import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ConsultingComponent from "../components/consulting/consulting"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { FAQSchemaGenerate } from "../schemas-org"

const query = graphql`
  query ConsultingPageDE {
    datoCmsConsultingPage(locale: { eq: "de" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
      headerBackground {
      url
      }
      sectionWithQuote {
        title
        text
        comment
        author
        authorPosition
      }
      digitalStrategyHeading
      digitalStrategyBeforeItems
      strategyConsultingItems {
        icon {
          url
        }
        text
      }
      digitalStrategyAfterItems
      expectImage {
        gatsbyImageData
        alt
      }
      expectHeading
      expectItems {
        title
        text
      }
      servicesHeading
      servicesCards {
        icon {
          url
        }
        title
        content
      }
      ctoHeading
      ctoText
      ctoItems {
        icon {
          url
        }
        text
      }
      contactImage {
        gatsbyImageData
        alt
      }
      buttonCta
      callToAction
      matchHeading
      matchContent
      showFaq
      faq {
        title
        faqItems {
          question
          answer
        }
        faqMoreQuestions
        faqConsultation
      }
      customSoftwareSolutionsHeading
    }
    allDatoCmsProject(
      filter: { showOnConsulting: { eq: true }, locale: { eq: "de" } }
    ) {
      nodes {
        title
        slug
        mainImage {
          alt
          gatsbyImageData(height: 260, layout: FIXED)
          url
        }
        projectBackground {
          hex
        }
        hashtags {
          hashtag
        }
      }
    }
  }
`

const Consulting = props => {
  const data = useStaticQuery(query)
  const lang = props.pageContext.langKey
  const { seo } = data.datoCmsConsultingPage
  const { faq, showFaq } = data.datoCmsConsultingPage

  return (
    <Layout whiteNav isHome lang={lang} location={props.location} showContact={false}>
      <Seo
        title={seo.title ?? ""}
        description={seo.description ?? ""}
        meta={seo.description ?? ""}
        image={seo && seo.image && seo.image.url}
        schemaMarkup={showFaq && FAQSchemaGenerate(faq[0].faqItems)}
        slug='digital-transformation-consulting'
      />
      <ConsultingComponent lang={lang} data={data} />
    </Layout>
  )
}

export default Consulting
